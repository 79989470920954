import {defineMessages} from 'react-intl';
import sharedMessages from '../shared-messages';

let messages = defineMessages({
    chirp: {
        defaultMessage: 'Chirp',
        description: 'Name for the chirp sound',
        id: 'gui.defaultProject.chirp'
    },
    variable: {
        defaultMessage: 'my variable',
        description: 'Name for the default variable',
        id: 'gui.defaultProject.variable'
    }
});

messages = {...messages, ...sharedMessages};

// use the default message if a translation function is not passed
const defaultTranslator = msgObj => msgObj.defaultMessage;

/**
 * Generate a localized version of the default project
 * @param {function} translateFunction a function to use for translating the default names
 * @return {object} the project data json for the default project
 */
const projectData = translateFunction => {
    const translator = translateFunction || defaultTranslator;
    return ({
        "targets": [
            {
                "isStage": true,
                "name": "Stage",
                "variables": {},
                "lists": {},
                "broadcasts": {},
                "blocks": {},
                "comments": {},
                "currentCostume": 0,
                "costumes": [
                    {
                        "assetId": "cd21514d0531fdffb22204e0ec5ed84a",
                        "name": "背景1",
                        "md5ext": "cd21514d0531fdffb22204e0ec5ed84a.svg",
                        "dataFormat": "svg",
                        "rotationCenterX": 240,
                        "rotationCenterY": 180
                    }
                ],
                "sounds": [
                    {
                        "assetId": "83a9787d4cb6f3b7632b4ddfebf74367",
                        "name": "啵",
                        "dataFormat": "wav",
                        "format": "",
                        "rate": 48000,
                        "sampleCount": 1123,
                        "md5ext": "83a9787d4cb6f3b7632b4ddfebf74367.wav"
                    }
                ],
                "volume": 100,
                "layerOrder": 0,
                "tempo": 60,
                "videoTransparency": 50,
                "videoState": "on",
                "textToSpeechLanguage": null
            },
            {
                "isStage": false,
                "name": "角色1",
                "variables": {},
                "lists": {},
                "broadcasts": {},
                "blocks": {
                    "-LrGL9Dyb2I@LYB@{/OX": {
                        "opcode": "event_whenarduinobegin",
                        "next": "lHMyun^d?OXNg1hComV7",
                        "parent": null,
                        "inputs": {},
                        "fields": {},
                        "shadow": false,
                        "topLevel": true,
                        "x": 559,
                        "y": -440
                    },
                    "lHMyun^d?OXNg1hComV7": {
                        "opcode": "arduino_zt_ztInit",
                        "next": "$vxUQ`|_/1:~sXS+k*$X",
                        "parent": "-LrGL9Dyb2I@LYB@{/OX",
                        "inputs": {},
                        "fields": {},
                        "shadow": false,
                        "topLevel": false
                    },
                    "td{XF%PbWd[%HG;hp;(|": {
                        "opcode": "arduino_zt_ztMotor",
                        "next": ",cwf`Yl8X]]yi2kFP0Fb",
                        "parent": "Zbb~.t_!BZi$l/1@W;$y",
                        "inputs": {
                            "ZTMOTORSPEED": [
                                3,
                                ".j9;v.L7GX}pDO?,#NQp",
                                [
                                    4,
                                    "80"
                                ]
                            ]
                        },
                        "fields": {
                            "MOTOR": [
                                "11",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    },
                    "/5ask@.;QX)%==CZ`1Z?": {
                        "opcode": "arduino_zt_ztLed",
                        "next": "|//LML=+S5iEc!amP~sm",
                        "parent": "1,nca@ug}BnlL;OqSmbs",
                        "inputs": {},
                        "fields": {
                            "LED": [
                                "100",
                                null
                            ],
                            "STATE": [
                                "LOW",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    },
                    "o3ZI[hhYY`x?.h*EwiKo": {
                        "opcode": "control_wait",
                        "next": "YC_]rpN[k`*r0SHN7zP|",
                        "parent": ",cwf`Yl8X]]yi2kFP0Fb",
                        "inputs": {
                            "DURATION": [
                                3,
                                "/-CkXwtz=(4SXUhehH?0",
                                [
                                    5,
                                    "1"
                                ]
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": false
                    },
                    ",cwf`Yl8X]]yi2kFP0Fb": {
                        "opcode": "arduino_zt_ztMotor",
                        "next": "o3ZI[hhYY`x?.h*EwiKo",
                        "parent": "td{XF%PbWd[%HG;hp;(|",
                        "inputs": {
                            "ZTMOTORSPEED": [
                                3,
                                "NYOSNHZ{Hox,fDfxHaoV",
                                [
                                    4,
                                    "80"
                                ]
                            ]
                        },
                        "fields": {
                            "MOTOR": [
                                "6",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    },
                    "$vxUQ`|_/1:~sXS+k*$X": {
                        "opcode": "control_wait",
                        "next": "N6V{Gc|$DZsj[-?Ak^wG",
                        "parent": "lHMyun^d?OXNg1hComV7",
                        "inputs": {
                            "DURATION": [
                                1,
                                [
                                    5,
                                    "1"
                                ]
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": false
                    },
                    "YC_]rpN[k`*r0SHN7zP|": {
                        "opcode": "arduino_zt_ztMotor",
                        "next": "^=hRxA9Ce_G2sc3(OuAc",
                        "parent": "o3ZI[hhYY`x?.h*EwiKo",
                        "inputs": {
                            "ZTMOTORSPEED": [
                                1,
                                [
                                    4,
                                    "0"
                                ]
                            ]
                        },
                        "fields": {
                            "MOTOR": [
                                "6",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    },
                    "^=hRxA9Ce_G2sc3(OuAc": {
                        "opcode": "arduino_zt_ztMotor",
                        "next": null,
                        "parent": "YC_]rpN[k`*r0SHN7zP|",
                        "inputs": {
                            "ZTMOTORSPEED": [
                                1,
                                [
                                    4,
                                    "0"
                                ]
                            ]
                        },
                        "fields": {
                            "MOTOR": [
                                "11",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    },
                    "1,nca@ug}BnlL;OqSmbs": {
                        "opcode": "control_wait",
                        "next": "/5ask@.;QX)%==CZ`1Z?",
                        "parent": "1|4*A6??H@HfuWOb[DdH",
                        "inputs": {
                            "DURATION": [
                                1,
                                [
                                    5,
                                    "0.2"
                                ]
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": false
                    },
                    "|//LML=+S5iEc!amP~sm": {
                        "opcode": "control_wait",
                        "next": "u/bM~dfCe^EU=QFZw^^s",
                        "parent": "/5ask@.;QX)%==CZ`1Z?",
                        "inputs": {
                            "DURATION": [
                                1,
                                [
                                    5,
                                    "0.6"
                                ]
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": false
                    },
                    "u/bM~dfCe^EU=QFZw^^s": {
                        "opcode": "arduino_zt_ztLed",
                        "next": "U5~:Jrj8wgbp7+y[RBw5",
                        "parent": "|//LML=+S5iEc!amP~sm",
                        "inputs": {},
                        "fields": {
                            "LED": [
                                "100",
                                null
                            ],
                            "STATE": [
                                "HIGH",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    },
                    "U5~:Jrj8wgbp7+y[RBw5": {
                        "opcode": "control_wait",
                        "next": null,
                        "parent": "u/bM~dfCe^EU=QFZw^^s",
                        "inputs": {
                            "DURATION": [
                                1,
                                [
                                    5,
                                    "0.2"
                                ]
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": false
                    },
                    "Zbb~.t_!BZi$l/1@W;$y": {
                        "opcode": "procedures_definition",
                        "next": "td{XF%PbWd[%HG;hp;(|",
                        "parent": null,
                        "inputs": {
                            "custom_block": [
                                1,
                                "cG6_3~^U(K!/p[yaX{^e"
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": true,
                        "x": 120,
                        "y": -602
                    },
                    "cG6_3~^U(K!/p[yaX{^e": {
                        "opcode": "procedures_prototype",
                        "next": null,
                        "parent": "Zbb~.t_!BZi$l/1@W;$y",
                        "inputs": {
                            "E6sNE!3=_xBU$aNV+f@T": [
                                1,
                                "]OHV00RArOrO}W0WC|io"
                            ],
                            "Y}C8D@dcT*p5o%llQm^X": [
                                3,
                                null,
                                "t|!;~pthO?Z3FKvi+aJ5"
                            ],
                            "dYADf@Q+Gj`3E@PoK}31": [
                                3,
                                null,
                                "b(7?=NZiD^au65Qr8}o)"
                            ],
                            "SFDO!XQpBv.g6*n(i|F.": [
                                1,
                                "fKjofZl$Mn%wxj`ae6)z"
                            ],
                            "ik%s+|T%IAmMtALrfc=)": [
                                1,
                                ")gUPl$o76]5m;Ks1vO2;"
                            ]
                        },
                        "fields": {},
                        "shadow": true,
                        "topLevel": false,
                        "mutation": {
                            "tagName": "mutation",
                            "children": [],
                            "proccode": "电机1 %n 电机2 %n 时长 %n",
                            "argumentids": "[\"E6sNE!3=_xBU$aNV+f@T\",\"SFDO!XQpBv.g6*n(i|F.\",\"ik%s+|T%IAmMtALrfc=)\"]",
                            "argumentnames": "[\"255\",\"150\",\"1.5\"]",
                            "argumentdefaults": "[\"\",\"\",\"\",\"\",\"\",\"\",\"\"]",
                            "warp": "false"
                        }
                    },
                    "]OHV00RArOrO}W0WC|io": {
                        "opcode": "argument_reporter_number",
                        "next": null,
                        "parent": "cG6_3~^U(K!/p[yaX{^e",
                        "inputs": {},
                        "fields": {
                            "VALUE": [
                                "255",
                                null
                            ]
                        },
                        "shadow": true,
                        "topLevel": false
                    },
                    "t|!;~pthO?Z3FKvi+aJ5": {
                        "opcode": "argument_reporter_string",
                        "next": null,
                        "parent": null,
                        "inputs": {},
                        "fields": {
                            "VALUE": [
                                "text",
                                null
                            ]
                        },
                        "shadow": true,
                        "topLevel": true,
                        "x": 1153,
                        "y": -549
                    },
                    "b(7?=NZiD^au65Qr8}o)": {
                        "opcode": "argument_reporter_string",
                        "next": null,
                        "parent": null,
                        "inputs": {},
                        "fields": {
                            "VALUE": [
                                "text",
                                null
                            ]
                        },
                        "shadow": true,
                        "topLevel": true,
                        "x": 1250,
                        "y": -549
                    },
                    "1|4*A6??H@HfuWOb[DdH": {
                        "opcode": "procedures_definition",
                        "next": "1,nca@ug}BnlL;OqSmbs",
                        "parent": null,
                        "inputs": {
                            "custom_block": [
                                1,
                                "8BU8B~iIjFphsRF.)SvG"
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": true,
                        "x": 121,
                        "y": -212
                    },
                    "8BU8B~iIjFphsRF.)SvG": {
                        "opcode": "procedures_prototype",
                        "next": null,
                        "parent": "1|4*A6??H@HfuWOb[DdH",
                        "inputs": {},
                        "fields": {},
                        "shadow": true,
                        "topLevel": false,
                        "mutation": {
                            "tagName": "mutation",
                            "children": [],
                            "proccode": "闪灯",
                            "argumentids": "[]",
                            "argumentnames": "[]",
                            "argumentdefaults": "[]",
                            "warp": "false"
                        }
                    },
                    "N6V{Gc|$DZsj[-?Ak^wG": {
                        "opcode": "procedures_call",
                        "next": "8y=Zf{Hbjn4]Ga4X9a@3",
                        "parent": "$vxUQ`|_/1:~sXS+k*$X",
                        "inputs": {
                            "E6sNE!3=_xBU$aNV+f@T": [
                                1,
                                [
                                    4,
                                    "200"
                                ]
                            ],
                            "Y}C8D@dcT*p5o%llQm^X": [
                                3,
                                null,
                                [
                                    10,
                                    "80"
                                ]
                            ],
                            "dYADf@Q+Gj`3E@PoK}31": [
                                3,
                                null,
                                [
                                    10,
                                    "1.1"
                                ]
                            ],
                            "SFDO!XQpBv.g6*n(i|F.": [
                                1,
                                [
                                    4,
                                    "200"
                                ]
                            ],
                            "ik%s+|T%IAmMtALrfc=)": [
                                1,
                                [
                                    4,
                                    "1.5"
                                ]
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": false,
                        "mutation": {
                            "tagName": "mutation",
                            "children": [],
                            "proccode": "电机1 %n 电机2 %n 时长 %n",
                            "argumentids": "[\"E6sNE!3=_xBU$aNV+f@T\",\"SFDO!XQpBv.g6*n(i|F.\",\"ik%s+|T%IAmMtALrfc=)\"]",
                            "warp": "false"
                        }
                    },
                    "NYOSNHZ{Hox,fDfxHaoV": {
                        "opcode": "argument_reporter_number",
                        "next": null,
                        "parent": ",cwf`Yl8X]]yi2kFP0Fb",
                        "inputs": {},
                        "fields": {
                            "VALUE": [
                                "150",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    },
                    "fKjofZl$Mn%wxj`ae6)z": {
                        "opcode": "argument_reporter_number",
                        "next": null,
                        "parent": "cG6_3~^U(K!/p[yaX{^e",
                        "inputs": {},
                        "fields": {
                            "VALUE": [
                                "150",
                                null
                            ]
                        },
                        "shadow": true,
                        "topLevel": false
                    },
                    ")gUPl$o76]5m;Ks1vO2;": {
                        "opcode": "argument_reporter_number",
                        "next": null,
                        "parent": "cG6_3~^U(K!/p[yaX{^e",
                        "inputs": {},
                        "fields": {
                            "VALUE": [
                                "1.5",
                                null
                            ]
                        },
                        "shadow": true,
                        "topLevel": false
                    },
                    "/-CkXwtz=(4SXUhehH?0": {
                        "opcode": "argument_reporter_number",
                        "next": null,
                        "parent": "o3ZI[hhYY`x?.h*EwiKo",
                        "inputs": {},
                        "fields": {
                            "VALUE": [
                                "1.5",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    },
                    "8y=Zf{Hbjn4]Ga4X9a@3": {
                        "opcode": "procedures_call",
                        "next": "y[cQV+:Z+|H8Nz1%}S#3",
                        "parent": "N6V{Gc|$DZsj[-?Ak^wG",
                        "inputs": {},
                        "fields": {},
                        "shadow": false,
                        "topLevel": false,
                        "mutation": {
                            "tagName": "mutation",
                            "children": [],
                            "proccode": "闪灯",
                            "argumentids": "[]",
                            "warp": "false"
                        }
                    },
                    "y[cQV+:Z+|H8Nz1%}S#3": {
                        "opcode": "procedures_call",
                        "next": "fFfj(NoJ}M#,.TZY=MPS",
                        "parent": "8y=Zf{Hbjn4]Ga4X9a@3",
                        "inputs": {
                            "E6sNE!3=_xBU$aNV+f@T": [
                                1,
                                [
                                    4,
                                    "200"
                                ]
                            ],
                            "SFDO!XQpBv.g6*n(i|F.": [
                                1,
                                [
                                    4,
                                    "150"
                                ]
                            ],
                            "ik%s+|T%IAmMtALrfc=)": [
                                1,
                                [
                                    4,
                                    "1.5"
                                ]
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": false,
                        "mutation": {
                            "tagName": "mutation",
                            "children": [],
                            "proccode": "电机1 %n 电机2 %n 时长 %n",
                            "argumentids": "[\"E6sNE!3=_xBU$aNV+f@T\",\"SFDO!XQpBv.g6*n(i|F.\",\"ik%s+|T%IAmMtALrfc=)\"]",
                            "warp": "false"
                        }
                    },
                    "fFfj(NoJ}M#,.TZY=MPS": {
                        "opcode": "procedures_call",
                        "next": "D+c4Gb~ov33dj*{v$A?l",
                        "parent": "y[cQV+:Z+|H8Nz1%}S#3",
                        "inputs": {},
                        "fields": {},
                        "shadow": false,
                        "topLevel": false,
                        "mutation": {
                            "tagName": "mutation",
                            "children": [],
                            "proccode": "闪灯",
                            "argumentids": "[]",
                            "warp": "false"
                        }
                    },
                    "D+c4Gb~ov33dj*{v$A?l": {
                        "opcode": "procedures_call",
                        "next": "jzLr.6A.,/HL{XqTU5Hz",
                        "parent": "fFfj(NoJ}M#,.TZY=MPS",
                        "inputs": {
                            "E6sNE!3=_xBU$aNV+f@T": [
                                1,
                                [
                                    4,
                                    "150"
                                ]
                            ],
                            "SFDO!XQpBv.g6*n(i|F.": [
                                1,
                                [
                                    4,
                                    "200"
                                ]
                            ],
                            "ik%s+|T%IAmMtALrfc=)": [
                                1,
                                [
                                    4,
                                    "1.5"
                                ]
                            ]
                        },
                        "fields": {},
                        "shadow": false,
                        "topLevel": false,
                        "mutation": {
                            "tagName": "mutation",
                            "children": [],
                            "proccode": "电机1 %n 电机2 %n 时长 %n",
                            "argumentids": "[\"E6sNE!3=_xBU$aNV+f@T\",\"SFDO!XQpBv.g6*n(i|F.\",\"ik%s+|T%IAmMtALrfc=)\"]",
                            "warp": "false"
                        }
                    },
                    "jzLr.6A.,/HL{XqTU5Hz": {
                        "opcode": "procedures_call",
                        "next": null,
                        "parent": "D+c4Gb~ov33dj*{v$A?l",
                        "inputs": {},
                        "fields": {},
                        "shadow": false,
                        "topLevel": false,
                        "mutation": {
                            "tagName": "mutation",
                            "children": [],
                            "proccode": "闪灯",
                            "argumentids": "[]",
                            "warp": "false"
                        }
                    },
                    ".j9;v.L7GX}pDO?,#NQp": {
                        "opcode": "argument_reporter_number",
                        "next": null,
                        "parent": "td{XF%PbWd[%HG;hp;(|",
                        "inputs": {},
                        "fields": {
                            "VALUE": [
                                "255",
                                null
                            ]
                        },
                        "shadow": false,
                        "topLevel": false
                    }
                },
                "comments": {},
                "currentCostume": 0,
                "costumes": [
                    {
                        "assetId": "5de48e72637db4d7fc7f6bb526354114",
                        "name": "造型1",
                        "bitmapResolution": 1,
                        "md5ext": "5de48e72637db4d7fc7f6bb526354114.svg",
                        "dataFormat": "svg",
                        "rotationCenterX": 48,
                        "rotationCenterY": 50
                    },
                    {
                        "assetId": "48bbc82a10af3d0e0b7d5c677097df0e",
                        "name": "造型2",
                        "bitmapResolution": 1,
                        "md5ext": "48bbc82a10af3d0e0b7d5c677097df0e.svg",
                        "dataFormat": "svg",
                        "rotationCenterX": 46,
                        "rotationCenterY": 53
                    }
                ],
                "sounds": [
                    {
                        "assetId": "4e080acae1c3dc65eef30f07c2b0d4a5",
                        "name": "啁啾",
                        "dataFormat": "wav",
                        "format": "",
                        "rate": 48000,
                        "sampleCount": 47524,
                        "md5ext": "4e080acae1c3dc65eef30f07c2b0d4a5.wav"
                    }
                ],
                "volume": 100,
                "layerOrder": 1,
                "visible": true,
                "x": -50,
                "y": 10,
                "size": 100,
                "direction": 90,
                "draggable": false,
                "rotationStyle": "all around"
            }
        ],
        "monitors": [],
        "device": "arduinoNano",
        "deviceType": "arduino",
        "pnpIdList": null,
        "programMode": "upload",
        "extensions": [],
        "deviceExtensions": [],
        "meta": {
            "semver": "3.0.0",
            "vm": "0.2.29",
            "agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/130.0.0.0 Safari/537.36 Edg/130.0.0.0"
        }
    });
};


export default projectData;
